import { render, staticRenderFns } from "./specialGoods.vue?vue&type=template&id=485b8ef1&scoped=true&"
import script from "./specialGoods.vue?vue&type=script&lang=js&"
export * from "./specialGoods.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485b8ef1",
  null
  
)

export default component.exports